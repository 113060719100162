import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Clutch from "../components/clutch"

import Cb_block from "../images/services/CB-blocks.png"
import Design from "../images/services/design-brand.png"
import Market from "../images/services/market.png"
import Dev from "../images/services/ecom-dev.png"


import { Helmet } from "react-helmet"
const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)


export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      url: ''
    };
  }
  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      url: document.location.href
    });
  }

  render() {


    const settings = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            dots: false,
            arrows: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false,
            arrows: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true
          }
        }
      ]
    };
    return (
      <Layout>
        <Helmet>
          <title>eCommerce Development, Design, Marketing for B Corps & Purpose Driven Brands</title>
          <link rel="canonical" href={this.state.url} />
          <meta name="description" content="CueForGood offers Design, Development and Marketing services. We leverage eCommerce for a global audience and love working with Ethical, Earth-Friendly & Purpose Driven Brands. " />
          <meta property="og:site_name" content="CueForGood" />
          <meta property="fb:app_id" content="289086684439915" />
          <meta property="og:url" content={this.state.url} />
          <meta property="og:title" content="eCommerce Development, Design, Marketing for B Corps & Purpose Driven Brands" />
          <meta property="og:description" content="CueForGood offers Design, Development and Marketing services. We leverage eCommerce for a global audience and love working with Ethical, Earth-Friendly & Purpose Driven Brands. " />
          <meta property="og:image" content="https://www.cueforgood.com/images/services-og.jpg" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content="CueForGood offers Design, Development and Marketing services. We leverage eCommerce for a global audience and love working with Ethical, Earth-Friendly & Purpose Driven Brands. " />
          <meta name="twitter:title" content="eCommerce Development, Design, Marketing for B Corps & Purpose Driven Brands" />
          <meta name="twitter:site" content="@CueForGood" />
          <meta name="twitter:image" content="https://www.cueforgood.com/images/services-og.jpg" />
        </Helmet>
        <div>
          <section class="service-section">
            <div className="container">
              <div className="service-inner">
                <div className="servicein servicein-left">
                  <h1>Our Services</h1>
                  <p className="h1-p">CueForGood is more than just a digital agency – we are your rock solid partner in growth and promoting sustainability.</p>
                  <h2>Understanding Your Needs</h2>
                  <p>The first thing we do with every project is getting to know you, understand your vision, business objectives, sustainability goals, challenges, assess your current digital footprint, and then map out a strategy that aligns with your goals.</p>
                </div>
                <div className="servicein servicein-right">
                  <img
                    src={Cb_block}
                    alt="Cb_block"
                    className=""
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="work-cb">
            <div className="container">
              <p className="work-cb-p">We believe <a href="/work">(see for yourself)</a> we are very strong in the following 3 areas of eCommerce:</p>
              <h2>Design. Code. Marketing.</h2>
              <p>We may start our relationship with one of the 3 key services, but we excel when we can collaborate with you from the initial planning to the launch and beyond.</p>
            </div>
          </div>
          <section className="services-prov">
            <div className="container">
              <div className="serv-prov-innr">
                <div className="serv-list ser-list1">
                  <div className="serv-p-img">
                    <img
                      src={Design}
                      alt="Cb_block"
                      className=""
                    />
                  </div>
                  <div className="serv-p-txt">
                    <span className="highlight-span">DESIGN</span>
                    <h2>Design & Branding</h2>
                    <p>Good design influences brand success.</p>
                    <p className="sv-last">We've witnessed it firsthand through our work with our in-house brands and hundreds of client stores since 2005. We take our understanding of your brand objectives and help reflect and recreate the same through our designs and user experience. </p>
                    <ul className="cst-ul">
                      <li>Brand Identity</li>
                      <li>Packaging Design</li>
                      <li>Illustration</li>
                      <li>eCommerce design</li>
                      <li>Web Design</li>
                      <li>UI/UX Development </li>
                      <li>Wireframes & Prototyping</li>
                    </ul>
                  </div>
                </div>
                <div className="serv-list ser-list2">
                            <div className="serv-p-img">
                               <img
                                      src={Dev}
                                      alt="Cb_block"
                                      className=""
                                    />
                            </div>
                            <div className="serv-p-txt">
                                <span className="highlight-span">CODE</span>
                                <h2>E-Commerce Development</h2>
                                <p>We specialize in PHP development and mainly 3 eCommerce platforms - Shopify, WooCommerce & Magento.</p>
                                <p className="sv-last">While we have built multiple successful sites on other platforms too, we will always be the first ones to tell you if we cannot do justice to a particular service or platform.</p>
                                <ul>
                                  <li>
                                    <h3>Magento:</h3>
                                    <p className="sv-last">From setting up your Magento store, and maintaining an existing Magento setup to optimizing its performance, we support stores that are on Magento.</p>
                                  </li>
                                  <li>
                                    <h3>Shopify:</h3>
                                    <p className="sv-last">Whether you're new to Shopify or looking to revamp your existing store, we offer theme customizations, feature enhancements, Shopify maintenance,  and more to make your store a sales powerhouse. Many of our clients are on Shopify Plus.</p>
                                  </li>
                                  <li>
                                    <h3>PHP:</h3>
                                    <p className="sv-last">From custom e-commerce stores to complex rental platforms, we have built, expanded, and maintained multiple custom sites in PHP.</p>
                                  </li>
                                  <li className="sv-last">
                                    <h3>WooCommerce:</h3>
                                    <p className="sv-last">Whether you are looking to build a WooCommerce site from scratch or optimize an existing site, we offer more than a decade of experience with WooCommerce to implement your requirements.</p>
                                  </li>
                                </ul>
                            </div>
                        </div>
                          <div className="serv-list ser-list3">
                            <div className="serv-p-img">
                               <img
                                      src={Market}
                                      alt="Cb_block"
                                      className=""
                                    />
                            </div>
                            <div className="serv-p-txt">
                                <span className="highlight-span">MARKETING</span>
                                <h2>Marketing & Content</h2>
                                <p className="sv-last">Luckily, it is easier to measure marketing success than in other disciplines. Our several case studies are proof of our skill in effectively promoting our clients and their impact.</p>
                                <ul>
                                    <li>
                                      <h3>Digital Advertising:</h3>
                                      <p className="sv-last">Our targeted digital advertising campaigns are designed to reach your audience effectively, driving leads and boosting sales.</p>
                                  </li>
                                  <li>
                                      <h3>Organic Search:</h3>
                                      <p className="sv-last">We offer comprehensive SEO strategies to improve your organic search rankings, driving more traffic to your site and increasing visibility.</p>
                                  </li>
                                  <li>
                                      <h3>GA4 Transition for Shopify:</h3>
                                      <p className="sv-last">Stay ahead of the curve with our seamless transition services to Google Analytics 4, ensuring you have the most up-to-date data analytics tools at your fingertips.</p>
                                  </li>
                                  <li>
                                      <h3>Conversion Rate Optimization (CRO):</h3>
                                      <p className="sv-last">Generally speaking it is easier & faster to double the conversion rate of a store than to double its traffic. Ideally one would want both. We have a strong understanding of eCommerce conversion optimization principles, and at the same time, we rely heavily on user behavior data to plan and implement CRO strategies.</p>
                                  </li>
                                  <li className="sv-last">
                                      <h3>Copywriting:</h3>
                                      <p className="sv-last">Words matter, and our copywriting services ensure that your brand message is conveyed clearly and compellingly, engaging your audience at every touchpoint.</p>
                                  </li>
                                </ul>
                            </div>
                        </div>
              </div>
            </div>
          </section>
          <div className="service-share-details">
            <div className="container">
              <div className="cb_good_section">
                <div className="contactus-area">
                    <h2 className="">Make Us Part of the Impact</h2>
                    <p>Please <a href="/contact">share details</a> of your story & project requirements</p>
                </div>
                </div>
              </div>
            </div>



          <Clutch />
        </div>
      </Layout>
    )

  }
}